@import url('https://fonts.googleapis.com/css?family=Montserrat:300|Varela+Round&display=swap');

:root {
  --site-header-font: 'Varela Round', sans-serif;
  --site-text-font: 'Montserrat', sans-serif;
}

/******************
SITE STYLES
*******************/

#root {
    background-image: linear-gradient(to right, #0c4d41 , teal);
    padding-bottom: 5vw;
}

body {
    background-image: linear-gradient(to right, #0c4d41 , teal);
}

.faded {
  opacity: .2;
}

.header-text {
  font-family: var(--site-header-font);
}

.site-text {
  font-family: var(--site-text-font);
  font-size: 1.5em;
  line-height: 1.5em;
}

.subheader {
  font-family: var(--site-text-font);
  font-size: 2em;
  line-height: 1.2em;
  margin: 0 11vw;
  text-align: center;
}

.title {
  font-family: var(--site-header-font);
  font-size: 3em;
  margin-bottom: 1.5em;
  margin-top: .5em;
  text-align: center;
}

.top-page-item {
  margin-top: 8em;
  background-color: white;
  border-radius: 30px 0;
  margin-left: 5vw;
  margin-right: 5vw;
  padding: 40px;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.75);
}

@media only screen and (max-width: 700px){

  .title {
    font-size: 2em;
  }
  .top-page-item {
    margin: 6em 5px;
    padding: 0px;
    width: calc(100vw - 10px);
  }

  .site-text {
    font-size: 1em;
    line-height: 1em;
  }
}

/******************
TOPBAR STYLES
*******************/
.lastNameHeader {
  padding-left: 1em;
}

.menu-link {
  float: right;
  margin: 5vh 0;
  text-align: center;
  width: 7vw;
}

.menu-anchor{
  color: white;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  transition: 1s;
}

.menu-anchor:hover {
  color: rgb(26,161,136);
}

.menu-anchor:hover::before {
  animation: menu-slide-in-left 1s;
  animation-fill-mode: forwards;
  content: '{ ';
  display: inline-block;
  left: -7px;
  opacity: 0;
  position: relative;
}
.menu-anchor:hover::after {
  animation: menu-slide-in-right 1s;
  animation-fill-mode: forwards;
  content: ' }';
  display: inline-block;
  opacity: 0;
  position: relative;
  right: -7px;
}

.nameContainer {
  display: inline-block;
  height: 100%;
  margin: auto;
  width: 20vw;
}

.nametext {
  color: white;
  font-family: var(--site-header-font);
  font-size: 2em;
  left: 12px;
  line-height: .8em;
  position: absolute;
  top: 12px;
}

.nametext > a {
  color: white;
  text-decoration: none;
}

.selected-menu-item {
  color: rgb(26,161,136);
}

.topbar {
  box-shadow: 1px 0 5px rgba(0,0,0,.75);
  position: fixed;
  background-color: black;
  height: 5em;
  top: 0;
  width: 100%;
  z-index: 1;
}

@media only screen and (max-width: 700px){
  .lastNameHeader, .firstNameHeader {
    font-size: .5em;
  }
}

@keyframes menu-slide-in-left {
  100% {left: -2px; opacity: 1;}
}
@keyframes menu-slide-in-right {
  100% {right: -2px; opacity: 1;}
}

/******************
MAIN BANNER STYLES
*******************/
.banner-img {
  box-shadow: 1px 1px 5px rgba(0,0,0,0.75);
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}

.banner-text {
  animation: fade-in 2s;
  animation-fill-mode: forwards;
  color: white;
  font-family: var(--site-header-font);
  font-size: 7em;
  margin: auto;
  opacity: 0;
  text-align: center;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.banner-text::before {
  animation: banner-slide-in-left 2s;
  animation-fill-mode: forwards;
  content: "{";
  left: -.5em;
  opacity: 0;
  position: relative;
}
.banner-text::after {
  animation: banner-slide-in-right 2s;
  animation-fill-mode: forwards;
  content: "}";
  right: -.5em;
  opacity: 0;
  position: relative;
}

.main-banner {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 4em);
  justify-content: center;
  margin-top: 5em;
  width: 100%;
}

@media only screen and (max-width: 700px){

  .banner-text {
    font-size: 3.5em;
  }
}

@media only screen and (max-width: 350px){
  .banner-text {
    font-size: 3em;
  }
}

@keyframes banner-slide-in-left {
  100% {left: -1em; opacity: 1;}
}
@keyframes banner-slide-in-right {
  100% {right: -1em; opacity: 1;}
}

@keyframes fade-in {
  100% {opacity: 1;}
}

/******************
ABOUT PAGE STYLES
*******************/

.about-picture {
  box-shadow: 0 0 2px 2px black;
  position: relative;
  float: left;
  height: 20em;
  margin: 0 2vw 2vh 2vw;
  object-fit: cover;
  width: 50%;
}

.about-resume {
  font-size: 1.25em;
  margin-bottom: 1.5em;
  text-align: center;
}

.about-tech-logo {
  width: 33%;
  height: 10vh;
  margin: 10px 0;
  object-fit: contain;
}

.about-technologies {
  margin-top: 4em;
}

.about-text {
  font-family: var(--site-text-font);
  font-size: 1.25em;
  line-height: 1.4em;
  margin: 2em;
}

.about-title {
  font-family: var(--site-header-font);
  font-size: 3em;
  margin-top: .5em;
  text-align: center;
}

.tech-selector {
  border-radius: 20px;
  cursor: pointer;
  display: inline-block;
  margin: 0 5px;
  transition: .5s;
  width: 25%;
}

.tech-selector-container {
  margin: 1em 12.5% 2em 12.5%;
  text-align: center;
}

.tech-selector:hover {
  box-shadow: 0 0 10px 5px teal;
  transition: 1s;
}

@media only screen and (max-width: 700px){
  .about-picture {
    float: none;
    width: calc(100vw - 25px);
  }

  .about-text {
    font-size: 1em;
    margin: 1em;
    text-align: justify
  }

  .about-title {
    font-size: 2.5em;
  }

  .tech-selector {
    font-size: 1em;
  }

}


/******************
SLIDE IN ITEMS STYLES
*******************/

.slider {
  background-color: white;
  display: flex;
  flex-direction: row;
  margin: 45px 3vw;
  padding: 10px;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.75);
}

.slider-content {
  width: 50%;
}

.slider-holder {
  height: 20vh;
  margin: 20px 0;
}

.slider-image {
  background-color: black;
  border-radius: 5px;
  color: white;
  height: 20vh;
  margin: 0 2vw;
  text-align: center;
}

.info-slide-in {
  opacity: 0;
  position: relative;
}

.info-slide-in-left {
  animation: info-slide-in-right 1.5s;
  animation-fill-mode: forwards;
  border-radius: 25px 0 0 25px;
  right: -2vw;
}

.info-slide-in-right {
  animation: info-slide-in-left 1.5s;
  animation-fill-mode: forwards;
  border-radius: 0 25px 25px 0;
  left: -2vw;
}

.slider-header{
  font-family: var(--site-header-font);
}

.slider-text {
  font-family: var(--site-text-font);
  margin: 0 2vw;
}

@keyframes info-slide-in-left {
  100% {opacity: 1; left: 0;}
}

@keyframes info-slide-in-right {
  100% {opacity: 1; right: 0;}
}

/******************
PROJECTS PAGE STYLES
*******************/

.project-description {
  display: inline-block;
  width: 75%;
}

.project-display {
  margin: 2vh 2vw;
}

.project-image {
  display: inline-block;
  margin: 1vh 2vw;
  width: 15%;
}

/******************
CONTACT PAGE STYLES
*******************/
.contact-banner {
  font-family: var(--site-header-font);
  font-size: 4em;
  text-align: center;
}

.social-links {
  margin-top: 3vh;
  text-align: center;
}

.social-links > a {
  margin: 0 1vw;
}

/******************
ANIMATION STYLES
*******************/

.slide-in-left {
  animation: slide-in-left 1.75s;
  animation-fill-mode: forwards;
  opacity: 0;
  position: relative;
  left: -2vw;
}

.slide-in-right {
  animation: slide-in-right 1.75s;
  animation-fill-mode: forwards;
  opacity: 0;
  position: relative;
  right: -5vw;
}

.fade-in {
  animation: fade-in 1.75s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes slide-in-left{
  100% {left: 0; opacity: 1;}
}

@keyframes slide-in-right{
  100% {right: 0; opacity: 1;}
}

@keyframes fade-in {
  100% {opacity: 1}
}
